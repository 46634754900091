import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import ReactMarkdown from "react-markdown"

import type {
  Strip as StripModel,
  Book,
  Series,
  StripPanel,
  File,
} from "../api/schema"

import classes from "./Strip.module.css"
import PreviewPanel from "../features/strip/PreviewPanel"
import StripContainer from "./StripContainer"
import { BookThumb, ExternalLink, Image, Tags } from "."
import { formatPublishDay } from "../util/moment"
import { panelAsset } from "../api/files"

type StripProps = {
  strip: StripModel
  controls?: JSX.Element
  isPreview?: boolean
}

export const Strip: React.FC<StripProps> = ({
  strip,
  controls,
  isPreview = false,
}) => {
  const panels = (strip.panels as StripPanel[]).map((p) => p.file)
  const hasBelowStripSection =
    strip.book ||
    (strip.link && strip.link_name) ||
    strip.body_text ||
    strip.keywords?.length

  const hasArchive =
    strip.series && typeof strip.series == "object"
      ? strip.series.slug === "ype"
      : strip.series === 1
  const keywords =
    strip.keywords?.length && hasArchive ? strip.keywords.slice(0, 5) : null
  return (
    <StripContainer>
      <StripHeader
        series={strip.series as Series}
        title={strip.title}
        timestamp={strip.published_on}
        status={strip.status}
      />
      <Panels images={panels} link={strip.link}>
        {controls ?? null}
        {isPreview && <PreviewPanel />}
      </Panels>
      {hasBelowStripSection ? (
        <div className={classes.belowStrip}>
          <div className={classes.text}>
            {strip.link && strip.link_name ? (
              <ExternalLink href={strip.link}>{strip.link_name}</ExternalLink>
            ) : null}
            {strip.body_text ? (
              <ReactMarkdown>{strip.body_text}</ReactMarkdown>
            ) : null}
            {keywords ? <Tags tags={keywords} /> : null}
          </div>
          {strip.book && typeof strip.book == "object" ? (
            <BookLink book={strip.book} />
          ) : null}
        </div>
      ) : null}
    </StripContainer>
  )
}

/* Strip header */

const StatusDot = ({ status }: { status: string }) => {
  if (status === "published") {
    return null
  }
  return <span className={`${classes.status} ${status}`} title={status} />
}

type StripHeaderProps = {
  series: Series
  status: string
  title: string
  timestamp: string
}

const StripHeader: React.FC<StripHeaderProps> = ({
  series,
  status,
  title,
  timestamp,
}) => (
  <div className={classes.header}>
    {!!series ? (
      <h1>
        {series.status !== "unlisted" ? (
          <Link
            to={series.slug === "ype" ? "/archief" : `/series/${series.slug}`}
          >
            {series.title}
          </Link>
        ) : (
          series.title
        )}
      </h1>
    ) : null}

    <span className={classes.episode}>
      <h2>
        {title}
        <StatusDot status={status} />
      </h2>
      <DateLabel timestamp={timestamp} />
    </span>
  </div>
)

type DateLabelProps = {
  timestamp: string
}

const DateLabel: React.FC<DateLabelProps> = ({ timestamp }) => (
  <time className={classes.date} dateTime={timestamp} title={timestamp}>
    {formatPublishDay(timestamp)}
  </time>
)

/* Panels */

const makeLink = (element: JSX.Element, link: string | null) => {
  return link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {element}
    </a>
  ) : (
    element
  )
}

type PanelsProps = {
  children: ReactNode
  images?: File[]
  link: string | null
}

const Panels: React.FC<PanelsProps> = ({ children, images, link }) => {
  return (
    <div className={classes.panels}>
      {children}
      {images?.map((image) => {
        const panel = <Image className={classes.panel} {...panelAsset(image)} />
        return (
          <React.Fragment key={image.id}>
            {makeLink(panel, link)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

/* Book link */

const BookLink = ({ book }: { book: Book }) => {
  return (
    <Link className={classes.bookLink} to={`/winkel/${book.slug}`}>
      <span>
        Deze strip
        <br />
        staat in:
      </span>
      <BookThumb book={book} />
    </Link>
  )
}
