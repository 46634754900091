import React from "react"
import { NavLink } from "react-router-dom"

import { Strip, StripPanel } from "../api/schema"

import classes from "./Thumb.module.css"
import { Image, ImageWithOverlay, Perspective } from "."
import placeholderImage from "./placeholder-strip.png"
import placeholderImage2 from "./placeholder-strip@2x.png"
// import previewImage from "./preview-strip.png"
// import previewImage2 from "./preview-strip@2x.png"
import { thumbAsset } from "../api/files"

const placeholder = () => ({
  src: placeholderImage,
  srcSet: `${placeholderImage}, ${placeholderImage2} 2x`,
})

// const preview = () => ({
//   src: previewImage,
//   srcSet: `${previewImage}, ${previewImage2} 2x`,
// })

const thumbMinAspectRatio = 0.333
const thumbMaxAspectRatio = 3
const pickThumbPanel = (strip: Strip): StripPanel | null => {
  if (
    !strip.panels ||
    !strip.panels?.length ||
    typeof strip.panels[0] == "string"
  ) {
    return null
  }
  // Find first normal aspect ratio panel
  for (let index = 0; index < strip.panels.length; index++) {
    const panel = strip.panels[index]
    if (typeof panel != "string" && panel.file.width && panel.file.height) {
      const aspectRatio = panel.file.width / panel.file.height
      if (
        aspectRatio >= thumbMinAspectRatio &&
        aspectRatio <= thumbMaxAspectRatio
      ) {
        return panel
      }
    }
  }
  // Default to first panel
  return strip.panels[0]
}

const thumb = (strip: Strip) => {
  const file = pickThumbPanel(strip)?.file
  return file ? thumbAsset(file) : null
}

interface ThumbProps {
  alt?: string
  isActive?: boolean
  lazy?: boolean
  strip: Strip
  subtitle?: any
}

export const Thumb: React.FC<ThumbProps> = ({
  alt,
  isActive = false,
  lazy = false,
  strip,
  subtitle,
}) => {
  const isDraft = strip.status === "draft"
  const isDeleted = strip.status === "archived"
  // const isPreview = !strip.panels && strip.status === "premium"
  const hasOverlay =
    strip.series &&
    typeof strip.series == "object" &&
    !strip.series.has_embedded_titles

  const className = [
    classes.Thumb,
    isActive ? classes.active : classes.inactive,
    isDraft ? classes.draft : "",
    isDeleted ? classes.deleted : "",
  ].join(" ")

  const _thumb = thumb(strip)
  const _placeholder = /*isPreview ? preview() :*/ placeholder()
  const image = (
    <Image
      {...(_thumb ?? _placeholder)}
      alt={alt || strip.title}
      loading={lazy ? "lazy" : undefined}
    />
  )
  const imageElement = hasOverlay ? (
    <ImageWithOverlay image={image}>
      <h2>{strip.title}</h2>
    </ImageWithOverlay>
  ) : (
    image
  )

  return (
    <div className={className}>
      <Perspective maxDegrees={0} hasCaption={!!subtitle}>
        {imageElement}
      </Perspective>
      {subtitle && <p>{subtitle}</p>}
    </div>
  )
}

interface ThumbLinkProps {
  strip: Strip
  path?: string
  subtitle?: JSX.Element | string
  isActive?: (match: any | null, location: any) => boolean
}

export const ThumbLink: React.FC<ThumbLinkProps> = ({
  strip,
  path = "/strip",
  subtitle,
  isActive,
}) => (
  <NavLink
    className={classes.ThumbLink}
    key={strip.id}
    to={`${path}/${strip.id}`}
    isActive={isActive}
    aria-label={strip.title}
  >
    <Thumb strip={strip} subtitle={subtitle} lazy />
  </NavLink>
)
